
import {computed, defineComponent} from "vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import router from "@/router";
import {useI18n} from "vue-i18n";

export default defineComponent({
  name: "oauth",
  props: {
    provider: {
      type: String,
      default: null,
    },
  },
  components: {},
  created: function () {
    this.startSSO(this.provider);
  },
  setup() {
    const store = useStore();
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    const parseParams = (querystring) => {
      // parse query string
      const params = new URLSearchParams(querystring);
      const obj = {};
      params.forEach((v, k) => {
        obj[k] = v;
      });

     
      return obj;
    };


    const GoogleAuth = () => {
      const data = parseParams(window.location.search);
     
      store
        .dispatch(Actions.LOGIN_SSO, {
          provider: "google",
          credentials: data,
        })
        .then(() => {
          router.push("/profile/billing");
        });
    };
    const FacebookAuth = () => {
      const data = parseParams(window.location.search);
      
      store
        .dispatch(Actions.LOGIN_SSO, {
          provider: "facebook",
          credentials: data,
        })
        .then(() => {
          router.push("/profile/billing");
        });
    };
    const GithubAuth = () => {
      const data = parseParams(window.location.search);
      
      store
        .dispatch(Actions.LOGIN_SSO, {
          provider: "github",
          credentials: data,
        })
        .then(() => {
          router.push("/profile/billing");
        });
    };

    const startSSO = (provider) => {
      switch (provider) {
        case "google":
          GoogleAuth();
          break;
        case "facebook":
          FacebookAuth();
          break;
        case "github":
          GithubAuth();
          break;
        default:
          console.log("No provider");
          break;
      }
    };
    return {
      startSSO,
      currentLanguage
    };
  },
});
